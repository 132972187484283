th.sticky-right,
td.sticky-right {
  @apply sticky right-0 bg-card;

  animation-timeline: scroll(inline) !important;
  animation: auto linear 0s 1 normal none running tbl-sticky-right;
}

@keyframes tbl-sticky-right {
  0% {
    filter: drop-shadow(rgba(0, 0, 0, 0.08) -2px 10px 6px);
  }
  75% {
    filter: drop-shadow(rgba(0, 0, 0, 0.08) -2px 10px 6px);
  }
  99% {
    filter: none;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
