.tab-nav {
  @apply max-w-full border-b border-gray-300;

  &__list {
    @apply flex h-auto w-full justify-start space-x-6 rounded-sm bg-card p-0 py-2;
  }

  &__trigger {
    @apply relative z-[1] cursor-pointer border border-transparent bg-card px-0 font-semibold;

    span {
      &:after {
        @apply absolute -bottom-2 -left-2 -right-2 -top-2 z-[-1] h-auto w-auto rounded-lg py-1;
        content: '';
      }
    }
    &:hover {
      &:before {
        @apply absolute -bottom-1 -left-2 -right-2 -top-1 z-[-1] h-auto w-auto rounded-lg bg-slate-200/50 py-1;
        content: '';
      }
    }
    &:after {
      @apply absolute bottom-[-0.70em] left-0 h-[0px] w-full translate-y-[1px] bg-linkby-purple transition-all;
      content: '';
    }
  }
  &__trigger[data-state='active'] {
    @apply border border-transparent !bg-card !text-linkby-purple;
    &:after {
      @apply absolute left-0 h-[3px] w-full translate-y-[1px] bg-linkby-purple transition-all;
      content: '';
    }
  }
}
