.dialog-scroll-sticky-footer {
  @apply py-0;

  .dialog-footer {
    @apply sticky bottom-0 z-[100000] -mx-6 bg-white px-6 py-2 pb-4;

    animation-timeline: scroll(block) !important;
    animation: auto linear 0s 1 normal none running dialog-footer-animation;
  }

  .dialog-header {
    @apply sticky top-0 z-[100000] -mx-6 bg-white px-6 py-2 py-8 pb-4;
    animation-timeline: scroll(block) !important;
    animation: auto linear 0s 1 normal none running dialog-header-animation;
  }
}

@keyframes dialog-footer-animation {
  0% {
    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px -2px 8px);
  }
  75% {
    filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 0px 8px);
  }
  99% {
    filter: none;
  }
}

@keyframes dialog-header-animation {
  0% {
    filter: none;
  }
  40% {
    filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 0px 3px);
    @apply py-6;
  }
  75% {
    filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 0px 8px);
    @apply py-5;
  }
  100% {
    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 12px);
    @apply py-4;
  }
}
.dialog-scroll {
  @apply overflow-y-scroll;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    position: absolute;
    background-color: transparent;
    padding: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }
}
