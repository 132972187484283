.linkby-admin-app .react-select {
  @apply w-80 text-sm font-normal text-primary;

  .select__control {
    @apply w-80 cursor-pointer border border-default-200 bg-background focus:outline-none;
    &.select__control--is-focused {
      @apply border shadow-none;
    }
  }
  .select__menu .select__option {
    @apply cursor-pointer truncate text-sm hover:bg-linkby-purple/10;
    &.select__option--is-selected {
      @apply bg-linkby-purple/80;
    }
  }
}
