.tab-filter {
  @apply flex justify-start rounded-sm bg-card p-0 py-1;

  &__list {
    @apply flex justify-start space-x-3 rounded-sm bg-card p-0 py-1;
  }

  &__trigger {
    @apply rounded-md border-2;
    &:hover {
      @apply border-linkby-purple;
    }
  }

  &__trigger[data-state='active'] {
    @apply rounded-md border-linkby-purple !bg-transparent !text-linkby-purple !shadow-none;
  }
}
