// Proxima Nova
@font-face {
  font-family: 'Proxima Nova';
  src: url('../../fonts/ProximaNova/ProximaNova-SemiboldIt.eot');
  src:
    url('../../fonts/ProximaNova/ProximaNova-SemiboldIt.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/ProximaNova/ProximaNova-SemiboldIt.woff2') format('woff2'),
    url('../../fonts/ProximaNova/ProximaNova-SemiboldIt.woff') format('woff'),
    url('../../fonts/ProximaNova/ProximaNova-SemiboldIt.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../fonts/ProximaNova/ProximaNova-Semibold.eot');
  src:
    url('../../fonts/ProximaNova/ProximaNova-Semibold.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/ProximaNova/ProximaNova-Semibold.woff2') format('woff2'),
    url('../../fonts/ProximaNova/ProximaNova-Semibold.woff') format('woff'),
    url('../../fonts/ProximaNova/ProximaNova-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../fonts/ProximaNova/ProximaNova-ThinIt.eot');
  src:
    url('../../fonts/ProximaNova/ProximaNova-ThinIt.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/ProximaNova/ProximaNova-ThinIt.woff2') format('woff2'),
    url('../../fonts/ProximaNova/ProximaNova-ThinIt.woff') format('woff'),
    url('../../fonts/ProximaNova/ProximaNova-ThinIt.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../fonts/ProximaNova/ProximaNova-BoldIt.eot');
  src:
    url('../../fonts/ProximaNova/ProximaNova-BoldIt.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/ProximaNova/ProximaNova-BoldIt.woff2') format('woff2'),
    url('../../fonts/ProximaNova/ProximaNova-BoldIt.woff') format('woff'),
    url('../../fonts/ProximaNova/ProximaNova-BoldIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../fonts/ProximaNova/ProximaNova-Light.eot');
  src:
    url('../../fonts/ProximaNova/ProximaNova-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/ProximaNova/ProximaNova-Light.woff2') format('woff2'),
    url('../../fonts/ProximaNova/ProximaNova-Light.woff') format('woff'),
    url('../../fonts/ProximaNova/ProximaNova-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../fonts/ProximaNova/ProximaNova-RegularIt.eot');
  src:
    url('../../fonts/ProximaNova/ProximaNova-RegularIt.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/ProximaNova/ProximaNova-RegularIt.woff2') format('woff2'),
    url('../../fonts/ProximaNova/ProximaNova-RegularIt.woff') format('woff'),
    url('../../fonts/ProximaNova/ProximaNova-RegularIt.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../fonts/ProximaNova/ProximaNova-Black.eot');
  src:
    url('../../fonts/ProximaNova/ProximaNova-Black.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/ProximaNova/ProximaNova-Black.woff2') format('woff2'),
    url('../../fonts/ProximaNova/ProximaNova-Black.woff') format('woff'),
    url('../../fonts/ProximaNova/ProximaNova-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../fonts/ProximaNova/ProximaNova-Bold.eot');
  src:
    url('../../fonts/ProximaNova/ProximaNova-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/ProximaNova/ProximaNova-Bold.woff2') format('woff2'),
    url('../../fonts/ProximaNova/ProximaNova-Bold.woff') format('woff'),
    url('../../fonts/ProximaNova/ProximaNova-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../fonts/ProximaNova/ProximaNova-Regular.eot');
  src:
    url('../../fonts/ProximaNova/ProximaNova-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/ProximaNova/ProximaNova-Regular.woff2') format('woff2'),
    url('../../fonts/ProximaNova/ProximaNova-Regular.woff') format('woff'),
    url('../../fonts/ProximaNova/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../fonts/ProximaNova/ProximaNova-Extrabld.eot');
  src:
    url('../../fonts/ProximaNova/ProximaNova-Extrabld.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/ProximaNova/ProximaNova-Extrabld.woff2') format('woff2'),
    url('../../fonts/ProximaNova/ProximaNova-Extrabld.woff') format('woff'),
    url('../../fonts/ProximaNova/ProximaNova-Extrabld.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../fonts/ProximaNova/ProximaNova-ExtrabldIt.eot');
  src:
    url('../../fonts/ProximaNova/ProximaNova-ExtrabldIt.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/ProximaNova/ProximaNova-ExtrabldIt.woff2') format('woff2'),
    url('../../fonts/ProximaNova/ProximaNova-ExtrabldIt.woff') format('woff'),
    url('../../fonts/ProximaNova/ProximaNova-ExtrabldIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../fonts/ProximaNova/ProximaNova-BlackIt.eot');
  src:
    url('../../fonts/ProximaNova/ProximaNova-BlackIt.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/ProximaNova/ProximaNova-BlackIt.woff2') format('woff2'),
    url('../../fonts/ProximaNova/ProximaNova-BlackIt.woff') format('woff'),
    url('../../fonts/ProximaNova/ProximaNova-BlackIt.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../fonts/ProximaNova/ProximaNova-LightIt.eot');
  src:
    url('../../fonts/ProximaNova/ProximaNova-LightIt.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/ProximaNova/ProximaNova-LightIt.woff2') format('woff2'),
    url('../../fonts/ProximaNova/ProximaNova-LightIt.woff') format('woff'),
    url('../../fonts/ProximaNova/ProximaNova-LightIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
