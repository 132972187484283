@mixin shadow-btn-sm {
  box-shadow:
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
    var(--shadow-color-start) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    var(--shadow-color-end) 0px 2px 5px 0px !important;
}

@mixin shadow-btn-lg {
  box-shadow:
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
    var(--shadow-color-start) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    var(--shadow-color-end) 0px 3px 9px 0px,
    var(--shadow-color-end) 0px 2px 5px 0px !important;
}

button.raised {
  @apply border border-transparent;

  &:not(.shadow-none) {
    @include shadow-btn-sm();
  }
  &:hover {
    @include shadow-btn-lg();
  }
  &.bg-white {
    --shadow-color-start: theme('colors.gray.900' / 16%);
    --shadow-color-end: theme('colors.gray.900' / 8%);
  }

  &.bg-destructive {
    --shadow-color-start: theme('colors.destructive.DEFAULT' / 16%);
    --shadow-color-end: theme('colors.destructive.DEFAULT' / 8%);
  }

  &.bg-purple-600 {
    --shadow-color-start: theme('colors.purple.600' / 16%);
    --shadow-color-end: theme('colors.purple.600' / 8%);
  }
}
